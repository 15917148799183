<!--
    @author bluefirex
    @date 09.06.21
-->
<template>
	<div class="acquisit-signature acquisit-signature-component">
		<template v-if="!allPagesValidated && check_pages_validity">
			<acquisit-header v-if="pages_not_validated_label" :label="pages_not_validated_label" />
			<acquisit-pages-error-summary class="error-summary" except_signature />
		</template>
		
		<template v-else>
			<transition :name="personTransitionName" mode="out-in">
				<signature-person-chooser v-if="mode == SIGNATURE_MODE_PERSONS_SINGLE"
				                          class="person-chooser"
				                          colorize_signature_status
				                          :persons="requiredPersons"
				                          :unsigned_label="unsigned_label"
				                          :signed_label="signed_label"
				                          :signed_on_behalf_label="on_behalf ? on_behalf.signed_label : null"
				                          :absent_label="absent_checkbox_label"
				                          :person_type_labels="person_type_labels"
				                          @select-single="onSelectSinglePerson($event)"
				                          @select-multiple="onSelectMultiplePersons($event)"
				                          :errors="errors"
				                          :has-current-errors="erroring">
					
					<template #header>
						<div class="persons-header">
							<acquisit-span v-if="overview_header_label && !(signatures_completed_label && allRequiredPersonsValidated)"
							             class="signature-persons-header"
							             :label="overview_header_label" />
							
							<transition name="move-fade-top">
								<acquisit-info-box v-if="missing_signatures_label && errorsMapped.missing_signatures.length"
								                 :label="missing_signatures_label"
								                 class="acquisit-has-errors"
								                 type="error" />
							</transition>
							
							<template v-if="errorsMapped.missing_persons.length">
								<template v-for="e in errorsMapped.missing_persons.filter(e => e.isUserVisible)">
									<acquisit-info-box :label="e.message"
									                   class="acquisit-has-errors"
									                   type="error" />
								</template>
							</template>
							
							<transition name="move-fade-top">
								<acquisit-info-box v-if="duplicate_signatures_label && Object.keys(errorsMapped.duplicate_signatures).length"
								                 :label="duplicate_signatures_label"
								                 class="acquisit-has-errors"
								                 type="error" />
							</transition>
							
							<transition name="move-fade-top">
								<acquisit-info-box v-if="requiredPersons.length && signatures_completed_label && allRequiredPersonsValidated && !Object.keys(errorsMapped.duplicate_signatures).length"
								                 :label="signatures_completed_label"
								                 type="info" />
							</transition>
							
							<transition name="move-fade-top">
								<div class="no-persons-chosen" v-if="requiredPersons.length == 0 && persons_conditions_no_match_label">
									<acquisit-string :source="persons_conditions_no_match_label" />
								</div>
							</transition>
						</div>
					</template>
					
					<div class="init-on-behalf" v-if="on_behalf && on_behalf.init_label">
						<div class="header" v-if="on_behalf.init_header_label"><h2>{{ language.parse(on_behalf.init_header_label) }}</h2></div>
						
						<acquisit-string v-if="on_behalf.init_description_label"
						               :source="on_behalf.init_description_label"
						               class="description"
						               />
						
						<acquisit-button type="text"
						               class="init-on-behalf-btn"
						               :label="on_behalf.init_label"
						               @click="openOnBehalf" />
					</div>
				</signature-person-chooser>
				
				<div v-else-if="on_behalf && mode == SIGNATURE_MODE_ON_BEHALF" class="on-behalf-container" key="on-behalf">
					<acquisit-components-container :components="onBehalfComponents"
					                             :has-current-errors="erroring"
					                             :errors="errorsMapped.on_behalf"
					                             @update:modelValue="onBehalfInput"
					                             :should-invalidate-current-page="false"
					                             :should-invalidate-all-persons="false"
					                             ref="$onBehalfComponentsContainer"
					                             class="on-behalf-components-container" />
				</div>
				
				<div v-else-if="mode == SIGNATURE_MODE_PERSONS_MULTIPLE" key="person-multi-chooser" class="person-chooser-container">
					<transition name="move-fade-top" appear>
						<acquisit-info-box v-if="on_behalf.signatures_completed_label && allPersonsSignedThemselves"
						                 :label="on_behalf.signatures_completed_label"
						                 class="all-persons-signed-themselves-info"
						                 type="error" />
					</transition>
					
					<acquisit-span v-if="on_behalf.select_persons_label"
					             :label="on_behalf.select_persons_label" />
					
					<signature-person-chooser class="person-chooser multiple"
					                          :persons="requiredPersons"
					                          :unsigned_label="unsigned_label"
					                          :signed_label="signed_label"
					                          :signed_on_behalf_label="on_behalf ? on_behalf.signed_label : null"
					                          :absent_label="absent_checkbox_label"
					                          :person_type_labels="person_type_labels"
					                          :selecting_multiple="true"
					                          colorize_signature_status
					                          @select-multiple="onSelectMultiplePersons($event)"
					                          :errors="errors"
					                          :has-current-errors="erroring" />
				</div>
				
				<div v-else-if="dataPerson" class="signature-container" key="signature">
					<header :class="['person-name', { 'on-behalf': selectedPersonsOnBehalfLabel }]" v-if="alternativeSignatureTypesVisible || !methodSignaturePad || (currentSignatureType && currentSignatureType.type != methodSignaturePad.type)">
						<v-svg file="acq/person" class="person-icon" />
						<h2 v-html="selectedPersonsOnBehalfLabel || selectedPersonsName" />
					</header>
					
					<div v-if="signature_types.length && (currentSignatureType === null || methodSignaturePad && currentSignatureType.type == methodSignaturePad.type)"
					     class="signature-pad-wrapper">
						<SignaturePad :modelValue="dataPerson.signature"
						              @update:modelValue="onSignaturePadInput"
						              :disabled="!methodSignaturePad || (dataPerson.on_behalf?.myself && dataPerson.absent) || alternativeSignatureTypesVisible"
						              ref="$signaturePad">
							<template #disabled>
								<div :class="['signature-pad-absent', 'signature-pad-placeholder', { 'on-behalf': selectedPersonsOnBehalfLabel }]" v-if="dataPerson.absent" key="absent">
									<div class="icon">
										<v-svg file="acq/signature-absent" class="icon" />
									</div>
									
									<div class="meta">
										<span :class="['person-name', { 'on-behalf': selectedPersonsOnBehalfLabel }]" v-html="selectedPersonsOnBehalfLabel || selectedPersonsName" />
										<acquisit-string class="label" :source="absent_checkbox_label" />
									</div>
								</div>
								
								<div class="alternative-signature-types" v-if="!(dataPerson.on_behalf?.myself && dataPerson.absent) && alternativeSignatureTypes.length && alternativeSignatureTypesVisible" key="alternative-signature-types">
									<template v-for="type in alternativeSignatureTypes">
										<div :class="['alternative-signature-type', 'type-' + type.type]"
										    :tabindex="0"
										     role="button"
										     :aria-label="type.choice_button.title ? language.parse(type.choice_button.title) + ' ' + language.parse(type.choice_button.subtitle) : undefined"
										     @click="selectSignatureType(type)"
										     @keypress.enter="selectSignatureType(type)"
										     :ref="'$option_' + type.type">
											<template v-if="type.choice_button.icon">
												<div class="meta with-image" aria-hidden="true">
													<img :src="type.choice_button.icon" alt="" crossorigin="anonymous" />
												</div>
											</template>
											
											<template v-else-if="type.choice_button.title">
												<div class="meta with-icon" aria-hidden="true">
													<div class="icon">
														<v-svg file="acq/bankid" v-if="methodBankID && type.type == methodBankID.type" multi-color />
													</div>
													
													<div class="description">
														<acquisit-string class="title" :source="type.choice_button.title" />
														<acquisit-string class="subtitle" :source="type.choice_button.subtitle" />
													</div>
												</div>
											</template>
											
											<div class="actions" aria-hidden="true">
												<v-svg file="arrows/right" />
											</div>
										</div>
									</template>
								</div>
							</template>
							
							<template #placeholder>
								<div :class="['signature-pad-placeholder', { 'on-behalf': selectedPersonsOnBehalfLabel }]" key="placeholder" v-if="methodSignaturePad">
									<div class="icon">
										<v-svg file="acq/touch" class="icon" />
									</div>
									
									<div class="meta">
										<span :class="['person-name', { 'on-behalf': selectedPersonsOnBehalfLabel }]" v-html="selectedPersonsOnBehalfLabel || selectedPersonsName" />
										<acquisit-string class="label" :source="methodSignaturePad.placeholder_label || methodSignaturePad.help_text" />
									</div>
								</div>
							</template>
						</SignaturePad>
					</div>
					
					<div v-if="currentSignatureType && ((methodBankID && currentSignatureType.type == methodBankID.type))"
					     class="bankid-wrapper">
						<acquisit-bank-id :modelValue="dataPerson?.signature ?? undefined"
						                  @update:modelValue="onBankIDInput($event)"
						                  ref="$bankID"
						                  :person="dataPerson"
						                  :mode="bankIDMode"
						                  :successText="currentSignatureType.success_label"
						                  :errorText="currentSignatureType.error_label"
						                  :helpText="currentSignatureType.placeholder_label" />
					</div>
					
					<!-- Actions when the signature pad is visible -->
					<div class="signature-actions regular" v-if="!alternativeSignatureTypesVisible && methodSignaturePad && (!currentSignatureType || currentSignatureType.type == methodSignaturePad.type)">
						<div class="alternative-methods" v-if="methodBankID">
							<div :class="['alternative-methods-btn', { disabled: (dataPerson.on_behalf?.myself && dataPerson.absent) }]"
							    :tabindex="0"
							     role="button"
							     :aria-label="alternatives_label + ' bankID'"
							     @click="showAlternativeOptions"
							     @keypress.enter="onAlternativeOptionsKeypressEnter(true)">
								<acquisit-string class="description" aria-hidden="true" :source="alternatives_label" />
								<img :src="language.parse(alternatives_image)" aria-hidden="true" alt="bankID" />
							</div>
						</div>
						
						<div class="reset">
							<acquisit-button type="flat" bordered
							                 :color="(dataPerson.on_behalf?.myself && dataPerson.absent) ? 'lightgrey' : 'red'"
							                 icon="acq/reset"
							                 :disabled="(dataPerson.on_behalf?.myself && dataPerson.absent) || false"
							                 :no-interact="(dataPerson.on_behalf?.myself && dataPerson.absent) || false"
							                 :label="methodSignaturePad.reset_label || methodSignaturePad.refresh_button_label || 'Try again'"
							                 @click="reset" />
						</div>
					</div>
					
					<!-- Actions when alternative options chooser is selected -->
					<div class="signature-actions centered" v-if="alternativeSignatureTypesVisible">
						<div class="alternative-methods" v-if="methodSignaturePad && methodBankID">
							<div class="alternative-methods-btn finger"
							    :tabindex="0"
							     role="button"
							     :aria-label="alternatives_label + ' ' + methodSignaturePad.choice_button.title"
							     @click="hideAlternativeOptions"
							     @keypress.enter="hideAlternativeOptions()">
								<acquisit-string class="description" aria-hidden="true" :source="alternatives_label" />
								<v-svg file="acq/touch" :style="{ color: color.baseVisuallyAndContextSafe.value.cssHSLA }" aria-hidden="true" />
								<label :style="{ color: color.baseVisuallyAndContextSafe.value.cssHSLA }" aria-hidden="true">{{ language.parse(methodSignaturePad.choice_button.title) }}</label>
							</div>
						</div>
					</div>
					
					<!-- Actions when alternative option is visible -->
					<div class="signature-actions" v-if="currentSignatureType && (!methodSignaturePad || currentSignatureType.type != methodSignaturePad.type)">
						<div class="reset">
							<acquisit-button type="flat" bordered
							                 :color="dataPerson.absent ? 'lightgrey' : 'red'"
							                 icon="acq/reset"
							                 :label="currentSignatureType.reset_label || currentSignatureType.refresh_button_label || 'Try again'"
							                 @click="reset" />
						</div>
					</div>
					
					<!-- Other options unrelated to current signature type -->
					<div class="other-options" v-if="dataPerson">
						<div class="option absent" v-if="absent_checkbox_label && requiredPersons.length > 1 && dataPerson.on_behalf?.myself">
							<acquisit-checkbox :modelValue="dataPerson.absent"
							                   @update:modelValue="onAbsent"
							                   :label="absent_checkbox_label" />
						</div>
						
						<div class="option contents-agreement" v-if="contents_agreement_label">
							<acquisit-checkbox :modelValue="dataPerson.content_agreed"
							                   @update:modelValue="onContentAgreed"
							                   :errors="errorsMapped.contents_agreement.length ? errorsMapped.contents_agreement : null"
							                   :has-current-errors="erroring"
							                   :label="contents_agreement_label" />
						</div>
					</div>
					
					<template class="errors" v-if="errorsByPerson[dataPerson.id]">
						<acquisit-info-box v-for="error in errorsByPerson[dataPerson.id]"
						                   type="error"
						                   :label="error.message" />
					</template>
					
					<acquisit-info-box v-if="before_signature_label && (!currentSignatureType || !(selectedPersonsName && dataPerson?.signature))" type="info" :label="before_signature_label" class="before-label" />
					<acquisit-info-box v-else-if="after_signature_label" type="warning" :label="after_signature_label" class="after-label" />
				</div>
			</transition>
		</template>
	</div>
</template>

<script setup lang="ts">
	import config from '@config'
	import SignaturePad from '@ui/signature/SignaturePad.vue'
	import SignaturePersonChooser from '@ui/signature/SignaturePersonChooser.vue'
	import type BankId from '@ui/extended/BankId.vue'
	import { usePersonsStore } from '@/stores/persons'
	import { useProtocolStore } from '@/stores/protocol'
	import { useBaseComponentProps, useBaseComponentEmits, useBaseComponent, usePersonsConditionsProps, usePersonsConditions } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/helpers'
	import { EventBus, ValidationError, useComponentLogger, ComponentsContainer, useRaygun } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
	import { createComponent, pageHasSemantic, partition } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/functions'
	import type { UILabel, UILabelOptional, Component, Person, ID } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
	import type { Condition, ConditionGroup } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/expressions'
	// @ts-ignore
	import bankIDSVG from '/images/bankid.svg?url'
	import { computed, nextTick, onBeforeUnmount, onMounted, type PropType, ref, watch } from 'vue'
	import { SIGNATURE_MODE_ON_BEHALF, SIGNATURE_MODE_PERSONS_MULTIPLE, SIGNATURE_MODE_PERSONS_SINGLE, SIGNATURE_MODE_SIGNING } from '@/validators/signature'
	import type { AnySignatureType, SignatureTypeBankID, SignatureTypeManual } from '@/lib/types'
	import { formatSigningOnBehalfLabel } from '@/lib/functions'
	import { errorMessage } from '@/lib/ui'
	import { decryptAES_GCM } from '@/lib/functions/crypto'
	import { useAPIStore } from '@/stores/api'
	
	const props = defineProps({
		...useBaseComponentProps(),
		...usePersonsConditionsProps(),
		
		signature_types: {
			type: Array as PropType<any[]>,
			required: true
		},
		
		/**
		 * Object declaring which person types need to sign to validate.
		 * Keys are the person types, values are either "none", "one", "all", "force-one" or "force-all"
		 */
		required_signatures: {
			type: Object,
			required: false,
			default: null
		},
		
		/**
		 * Allow "absent" as a valid signature for required_signatures?
		 */
		absent_as_required_signature: {
			type: Boolean,
			required: false,
			default: true
		},
		
		/**
		 * Labels for the person types.
		 * Keys are the person types, values are the labels
		 */
		person_type_labels: {
			type: Object as PropType<{ [type: string]: UILabel }>,
			required: false,
			default: null
		},
		
		/**
		 * Conditions for each person type
		 */
		persons_conditions: {
			type: Object as PropType<{ [type: string]: Condition|ConditionGroup }>,
			required: false,
			default: null
		},
		
		/**
		 * Optional label for the header above the persons in the initial step
		 */
		overview_header_label: {
			type: [String, Object] as PropType<UILabelOptional>,
			required: false,
			default: null
		},
		
		/**
		 * Check whether all pages are valid before allowing to sign
		 */
		check_pages_validity: {
			type: Boolean,
			required: false,
			default() {
				return config.signature.validate_pages
			}
		},
		
		/**
		 * Label to show when not all pages have been validated
		 */
		pages_not_validated_label: {
			type: [String, Object] as PropType<UILabelOptional>,
			required: false,
			default: null
		},
		
		/**
		 * Label for the button to choose an alternative signature method
		 */
		alternatives_label: {
			type: [String, Object] as PropType<UILabelOptional>,
			required: false,
			default: null
		},
		
		/**
		 * Image for the button to choose an alternative signature method
		 */
		alternatives_image: {
			type: [String, Object] as PropType<UILabelOptional>,
			required: false,
			default() {
				return bankIDSVG
			}
		},
		
		/**
		 * Label for when a person hasn't signed yet
		 */
		unsigned_label: {
			type: [String, Object] as PropType<UILabelOptional>,
			required: false,
			default: null
		},
		
		/**
		 * Label for when a person has signed with any method
		 */
		signed_label: {
			type: [String, Object] as PropType<UILabelOptional>,
			required: false,
			default: null
		},
		
		/**
		 * Label to show when one or more persons are missing signatures
		 */
		missing_signatures_label: {
			type: [String, Object] as PropType<UILabelOptional>,
			required: false,
			default: null
		},
		
		/**
		 * Label to show when two or more persons share the same signature
		 */
		duplicate_signatures_label: {
			type: [String, Object] as PropType<UILabelOptional>,
			required: false,
			default: null
		},
		
		/**
		 * Label to show when signatures are completed
		 */
		signatures_completed_label: {
			type: [String, Object] as PropType<UILabelOptional>,
			required: false,
			default: null
		},
		
		/**
		 * Configuration for signing for another person
		 */
		on_behalf: {
			type: Object,
			required: false,
			default: null
		},
		
		/**
		 * Configuration for power of attorney
		 */
		power_of_attorney: {
			type: Object,
			required: false,
			default: null
		},
		
		/**
		 * Label for the checkbox to state "Person is absent"
		 */
		absent_checkbox_label: {
			type: [String, Object] as PropType<UILabelOptional>,
			required: false,
			default: null
		},
		
		/**
		 * Label for the checkbox stating that the user has read the protocol
		 */
		contents_agreement_label: {
			type: [String, Object] as PropType<UILabelOptional>,
			required: false,
			default: null
		},
		
		/**
		 * Label to show before the person has signed
		 */
		before_signature_label: {
			type: [String, Object] as PropType<UILabelOptional>,
			required: false,
			default: null
		},
		
		/**
		 * Label to show after the person has signed
		 */
		after_signature_label: {
			type: [String, Object] as PropType<UILabelOptional>,
			required: false,
			default: null
		},
		
		/**
		 * The mode of the signature page, refers to SIGNATURE_MODE_* constants
		 */
		mode: {
			type: String,
			required: false,
			default: SIGNATURE_MODE_PERSONS_SINGLE
		},
		
		modelValue: {
			type: [String, Object],
			required: false,
			default: null
		}
	})
	
	const emit = defineEmits([
		...useBaseComponentEmits(),
		'update:modelValue'
	])
	
	const base = useBaseComponent(props, emit)
	const { erroring, language, color } = base
	
	const log = useComponentLogger('components/ui/extended/Signature', props)
	
	const protocolStore = useProtocolStore()
	const personsStore = usePersonsStore()
	
	const personsConditions = usePersonsConditions(props)
	
	const $bankID = ref<typeof BankId|undefined>()
	const $option_bankid = ref<typeof HTMLDivElement|undefined>()
	const $option_manual = ref<typeof HTMLDivElement|undefined>()
	const $onBehalfComponentsContainer = ref<typeof ComponentsContainer|undefined>()
	const $signaturePad = ref<typeof SignaturePad|undefined>()
	
	const personTransitionName = ref('move-fade-left')
	const currentSignatureType = ref<AnySignatureType|null>(null)
	const onBehalfComponents = ref<Component[]>([])
	const onBehalfValues = ref<any>(null)
	const alternativeSignatureTypesVisible = ref(false)
	const multiSelectedPersons = ref<Person[]>([])
	const bankIDMode = ref<string>('init')
	
	const requiredPersons = computed(() => personsStore.required_list)
	const allRequiredPersonsValidated = computed(() => personsStore.all_required_validated)
	const pages = computed(() => protocolStore.pages)
	
	const allPagesValidated = computed(() => {
		for (let page of pages.value) {
			if (!page.enabled) {
				continue
			}
			
			if (pageHasSemantic(page, ['signature', 'summary'], 'OR')) {
				continue
			}
			
			if (!page.data.validated) {
				return false
			}
		}
		
		return true
	})
	
	const allPersonsSignedThemselves = computed(() => {
		for (let person of requiredPersons.value) {
			if (!person.signature || !person.on_behalf?.myself) {
				return false
			}
		}
		
		return true
	})
	
	const methodSignaturePad = computed((): SignatureTypeManual => {
		return findSignatureType('manual')
	})
	
	const methodBankID = computed((): SignatureTypeBankID => {
		return findSignatureType('bankid')
	})
	
	const findSignatureType = (type: string) => {
		for (let t of props.signature_types) {
			if (t.type == type) {
				return t
			}
		}
		
		return null
	}
	
	const alternativeSignatureTypes = computed((): AnySignatureType[] => {
		return [
			methodSignaturePad.value,
			methodBankID.value,
		].filter(x => x ? currentSignatureType.value ? x.type != currentSignatureType.value.type : (x.type != 'manual') : false)
	})
	
	const selectedPersonsName = computed(() => {
		return personsStore.selected
		                   .map(formatPersonName)
		                   .filter(x => !!x)
		                   .join(', ')
	})
	
	const formatPersonName = (person: Person|null|undefined) => {
		if (!person || (!person.firstname && !person.name)) {
			return null
		}
		
		return [ person.firstname, person.name ].filter(x => !!x).join(' ')
	}
	
	const selectedPersonsOnBehalfLabel = computed(() => {
		if (props.on_behalf?.signing_on_behalf_label && dataPerson.value?.on_behalf?.myself === false) {
			return formatSigningOnBehalfLabel(props.on_behalf.signing_on_behalf_label, selectedPersonsName.value, dataPerson.value.on_behalf.name, language.current)
		}
		
		return null
	})
	
	/**
	 * The person that should receive all data
	 * If there are multiple persons in this.selected_persons then this will
	 * return the first person only to copy the data to the others later
	 */
	const dataPerson = computed((): Person|null => {
		return personsStore.selected.length ? personsStore.selected[0] : null
	})
	
	const errorsMapped = computed(() => {
		let mapped = {
			on_behalf: [] as ValidationError[],
			contents_agreement: [] as ValidationError[],
			duplicate_signatures: {} as Record<string, ValidationError>,
			missing_signatures: [] as ValidationError[],
			missing_persons: [] as ValidationError[]
		}
		
		const errors = Array.isArray(props.errors) ? props.errors : []
		
		for (let error of errors) {
			switch (error.key) {
				case 'all_required_validated':
					mapped.missing_signatures.push(error)
					break
				
				case 'duplicate_signature':
					mapped.duplicate_signatures[error.owner.person_id] = error
					break
				
				case 'content_agreed':
					mapped.contents_agreement.push(error)
					break
				
				case 'required_signatures':
					mapped.missing_signatures.push(error)
					break
				
				case 'person_types':
				case 'persons':
					mapped.missing_persons.push(error)
					break
				
				default:
					mapped.on_behalf.push(error)
			}
		}
		
		return mapped
	})
	
	const errorsByPerson = computed(() => {
		return partition<ValidationError>(
			(props.errors ?? []).filter(e => e.isUserVisible),
			e => e.owner?.person_id ?? 'unknown'
		)
	})
	
	const emitValue = () => {
		let value = {
			mode: props.mode,
			person_ids: personsStore.selected.map(p => p.id)
		} as any
		
		switch (value.mode) {
			case SIGNATURE_MODE_PERSONS_MULTIPLE:
				value.multi_selected_persons = [...multiSelectedPersons.value]
				value.on_behalf = onBehalfComponents.value
				break
			
			case SIGNATURE_MODE_ON_BEHALF:
				value.on_behalf = onBehalfComponents.value
				break
		}
		
		emit('update:modelValue', value)
	}
	
	const selectSignatureType = (type: AnySignatureType) => {
		for (let person of personsStore.selected) {
			if (person.absent) {
				continue
			}
			
			hideAlternativeOptions()
			
			if (currentSignatureType.value != type) {
				setPersonSignatureType(person, type)
				setPersonSignature(person, null)
				
				currentSignatureType.value = type
				bankIDMode.value = 'init'
				$bankID.value?.retryAuth()
			} else {
				currentSignatureType.value = type
			}
		}
	}
	
	/*
	 * TODO: Maybe remove?
	 */
	const invalidatePerson = (person: Person) => {
		personsStore.invalidatePerson(person)
		setPersonContentAgreed(person, false)
		protocolStore.invalidateSignaturePage()
	}
	
	const selectPersons = (arg: Person[]|ID[]|null) => {
		personsStore.select(arg)
	}
	
	const unselectAllPersons = () => {
		personsStore.select(null)
	}
	
	const onSelectSinglePerson = (persons: Person[]) => {
		let person = persons[0]
		
		if (!person) {
			return false
		}
		
		setPersonOnBehalf(person, {
			name: null,
			myself: true,
			powerOfAttorneyReceived: null,
			images: []
		})
		
		selectPersons([ person ])
	}
	
	const onSelectMultiplePersons = (persons: Person[]) => {
		multiSelectedPersons.value = persons
		emitValue()
	}
	
	const openOnBehalf = () => {
		selectPersons(null)
		base.emitProperty('mode', SIGNATURE_MODE_ON_BEHALF)
	}
	
	const createOnBehalfComponents = () => {
		let components: Component[] = []
		
		if (!props.on_behalf) {
			onBehalfComponents.value = components
			return
		}
		
		let onBehalfInformationComponents: Component[] = []
		
		// Label explaining the input or the process
		if (props.on_behalf.explanation_label) {
			onBehalfInformationComponents.push(createComponent('acquisit-span', {
				uid: 'on-behalf-explanation',
				label: props.on_behalf.explanation_label,
				space_top: false
			}))
		}
		
		// Input for the name of the person signing
		onBehalfInformationComponents.push(createComponent('acquisit-input', {
			uid: 'on-behalf-name',
			label: props.on_behalf.name_label,
			modelValue: null,
			disabled: false,
		}, true))
		
		// Append onBehalf
		components = components.concat(onBehalfInformationComponents)
		
		// Figure out power of attorney
		if (props.power_of_attorney) {
			let powerOfAttorneyComponents: Component[] = []
			
			switch (props.power_of_attorney.type) {
				// Question: "Does the broker/whoever have power of attorney?"
				case 'question':
					powerOfAttorneyComponents = [
						createComponent('acquisit-spacer', {
							uid: 'power-of-attorney-spacer',
							size: 'small',
						}),
						createComponent('acquisit-span', {
							uid: 'power-of-attorney-question',
							label: props.power_of_attorney.question_label
						})
					]
					
					let powerOfAttorneyYesCheckbox = createComponent('acquisit-checkbox', {
						uid: 'on-behalf-power-of-attorney-received-yes',
						label: props.power_of_attorney.yes_label,
						modelValue: null,
						disabled: false,
						radio: true,
						boldWhenChecked: true
					}, true)
					
					powerOfAttorneyYesCheckbox.linked = ['on-behalf-power-of-attorney-received-no']
					
					let powerOfAttorneyNoCheckbox = createComponent('acquisit-checkbox', {
						uid: 'on-behalf-power-of-attorney-received-no',
						label: props.power_of_attorney.no_label,
						modelValue: null,
						radio: true,
						boldWhenChecked: true
					}, true)
					
					powerOfAttorneyNoCheckbox.linked = ['on-behalf-power-of-attorney-received-yes']
					
					if (props.power_of_attorney.power_of_attorney_required_label) {
						powerOfAttorneyNoCheckbox.children = {
							default: [
								createComponent('acquisit-info-box', {
									uid: 'on-behalf-power-of-attorney-required',
									type: 'error',
									label: props.power_of_attorney.power_of_attorney_required_label
								})
							]
						}
					}
					
					powerOfAttorneyComponents.push(powerOfAttorneyYesCheckbox)
					powerOfAttorneyComponents.push(powerOfAttorneyNoCheckbox)
					break;
				
				// Image upload only: "Broker/whoever should have a power of attorney. Please upload one, if you like."
				case 'image_only':
					powerOfAttorneyComponents = [
						createComponent('acquisit-spacer', {
							uid: 'on-behalf-power-of-attorney-images-info-spacer',
							size: 'tiny',
						})
					]
					
					if (props.power_of_attorney.upload_image_label) {
						if (props.power_of_attorney.explanation_label) {
							powerOfAttorneyComponents.push(createComponent('acquisit-span', {
								uid: 'on-behalf-power-of-attorney-images-info',
								label: props.power_of_attorney.explanation_label || null
							}))
						}
						
						powerOfAttorneyComponents.push(createComponent('acquisit-image-input', {
							uid: 'on-behalf-power-of-attorney-images',
							semantic: ['power_of_attorney'],
							modelValue: [{
								images: [],
								add_image_label: props.power_of_attorney.upload_image_label
							}]
						}))
					}
					
					break;
			}
			
			if (powerOfAttorneyComponents.length) {
				components = components.concat(powerOfAttorneyComponents)
			}
		}
		
		// Optional information
		if (props.on_behalf?.info_label) {
			components.push(createComponent('acquisit-info-box', {
				uid: 'on-behalf-info',
				label: props.on_behalf.info_label
			}))
		}
		
		onBehalfComponents.value = components
	}
	
	const setPersonAbsent = (person: Person, absent: boolean = true) => {
		personsStore.setAbsent(person, absent)
		
		if (absent) {
			setPersonSignature(person, '@')
			setPersonSignatureType(person, null)
		} else {
			return setPersonSignature(person, person.signature == '@' ? null : person.signature)
		}
	}
	
	const setPersonOnBehalf = (person: Person, { name, myself, powerOfAttorneyReceived, images }) => {
		personsStore.setOnBehalf(person, {
			name,
			myself,
			powerOfAttorneyReceived,
			images: images ?? []
		})
		
		if (!myself) {
			setPersonAbsent(person, false)
		}
		
		setPersonContentAgreed(person, false)
	}
	
	const setPersonContentAgreed = (person: Person, agreed: boolean = false) => {
		personsStore.setContentAgreed(person, agreed)
	}
	
	const setPersonSignatureType = (person: Person, type: AnySignatureType|null) => {
		personsStore.setSignatureType(person, type)
	}
	
	const setPersonSignature = (person: Person, signature: any) => {
		personsStore.setSignature(person, signature)
		personsStore.invalidatePerson(person)
		protocolStore.invalidateSignaturePage()
	}
	
	const showAlternativeOptions = () => {
		const method = alternativeSignatureTypes.value[0] ?? null
		
		if (method) {
			selectSignatureType(method)
			
			for (let person of personsStore.selected) {
				setPersonSignature(person, null)
			}
		}
		
		// Re-enable when we have more than one alternative again
		/*alternativeSignatureTypesVisible.value = true
		
		for (let person of personsStore.selected) {
			setPersonSignature(person, null)
		}*/
	}
	
	const hideAlternativeOptions = () => {
		alternativeSignatureTypesVisible.value = false
	}
	
	const onAlternativeOptionsKeypressEnter = async (show: boolean) => {
		if (show) {
			showAlternativeOptions()
			
			await nextTick()
			
			let firstOption = alternativeSignatureTypes.value[0]
			
			if (firstOption) {
				let $ref = {
					'bankid': $option_bankid,
					'manual': $option_manual,
				}[firstOption.type] ?? undefined
				
				if ($ref?.value) {
					;(<any> $ref.value).focus()
				}
			}
		} else {
			hideAlternativeOptions()
		}
	}
	
	const reset = () => {
		alternativeSignatureTypesVisible.value = !methodSignaturePad.value
		currentSignatureType.value = methodSignaturePad.value ?? null
		$signaturePad.value?.clearCanvas()
		
		for (let person of personsStore.selected) {
			setPersonSignature(person, null)
		}
	}
	
	const onSignaturePadInput = (signature: string) => {
		for (let person of personsStore.selected) {
			setPersonSignature(person, signature)
			setPersonSignatureType(person, methodSignaturePad.value)
		}
		
		currentSignatureType.value = methodSignaturePad.value
	}
	
	const onBankIDInput = (signature: string) => {
		let signatureMethod = methodBankID.value!
		
		for (let person of personsStore.selected) {
			setPersonSignature(person, signature)
			setPersonSignatureType(person, signatureMethod)
		}
		
		alternativeSignatureTypesVisible.value = false
		currentSignatureType.value = signatureMethod
	}
	
	const onBehalfInput = () => {
		if ($onBehalfComponentsContainer.value) {
			onBehalfValues.value = $onBehalfComponentsContainer.value.extractValues()
			emitValue()
		}
	}
	
	const onAbsent = (absent: boolean) => {
		for (let person of personsStore.selected) {
			setPersonAbsent(person, absent)
		}
		
		if (absent) {
			// The signature pad gets disabled and shows a message regarding absent
			currentSignatureType.value = methodSignaturePad.value
		}
		
		if (methodSignaturePad.value) {
			hideAlternativeOptions()
		} else if (!absent) {
			showAlternativeOptions()
		}
	}
	
	const onContentAgreed = (agreed: boolean) => {
		for (let person of personsStore.selected) {
			setPersonContentAgreed(person, agreed)
		}
		
		base.emitProperty('errors', null)
	}
	
	// When the page changes we need to reset the signature type
	// so bankID doesn't open on its own
	const onLocationChange = () => {
		for (let person of personsStore.selected) {
			if (person.signature_type && person.signature_type.type && !person.signature) {
				setPersonSignatureType(person, null)
			}
		}
	}
	
	const triggerErrorAnimationForSubComponent = (uid, owner) => {
		if ($onBehalfComponentsContainer.value) {
			$onBehalfComponentsContainer.value.triggerErrorAnimation(uid)
		} else {
			log.warnFrom('triggerErrorAnimationForSubComponent', 'Could not find $ref for uid', uid, 'with owner', owner)
		}
	}
	
	const trySessionRestore = async (): Promise<boolean> => {
		// Check if we are restoring from a bankID redirect
		const url = new URL(window.location.href)
		
		if (url.searchParams.has('bid')) {
			const signingSessionEncoded = decodeURIComponent(url.searchParams.get('bid') ?? '')
			
			if (signingSessionEncoded && methodBankID.value) {
				try {
					const password = (useAPIStore().token ?? 'default').substring(0, 7)
					const decrypted = await decryptAES_GCM(signingSessionEncoded, password)
					const decoded = JSON.parse(decrypted)
					
					if (!Array.isArray(decoded)) {
						throw new Error('Invalid format (1)')
					}
					
					if (!decoded.length) {
						throw new Error('Invalid format (2)')
					}
					
					// Update store
					const persons: Person[] = []
					
					for (let data of decoded) {
						const person = personsStore.by_id[data.id]
						
						if (person) {
							setPersonSignatureType(person, methodBankID.value)
							setPersonOnBehalf(person, {
								name: data.on_behalf?.name,
								myself: data.on_behalf?.myself ?? true,
								powerOfAttorneyReceived: data.on_behalf?.powerOfAttorneyReceived,
								images: data.on_behalf?.images,
							})
							
							persons.push(person)
						}
					}
					
					selectPersons(persons)
					
					if (persons.length) {
						// Update this component
						currentSignatureType.value = methodBankID.value
						bankIDMode.value = 'verify'
						hideAlternativeOptions()
						
						if (persons.length > 1) {
							multiSelectedPersons.value = persons
						}
						
						log.infoFrom('trySessionRestore', 'Restored signature session', decoded)
						return true
					} else {
						await errorMessage('Could not continue signature session: Invalid session data.')
						return false
					}
				} catch (e: any) {
					console.error('Error restoring signature session', e)
					useRaygun().send(e, ['bankID'])
					await errorMessage('Could not continue signature session. Please try again.')
				}
			}
		}
		
		return false
	}
	
	watch(() => personsStore.selected, (newPersons) => {
		if (!newPersons || !newPersons.length) {
			currentSignatureType.value = null
			base.emitProperty('mode', SIGNATURE_MODE_PERSONS_SINGLE)
		} else {
			// We use only the first one to determine the type and assume the rest share the same
			// noinspection UnnecessaryLocalVariableJS
			let signatureType = newPersons[0].signature_type ?? null
			
			currentSignatureType.value = signatureType as any
			alternativeSignatureTypesVisible.value = !methodSignaturePad.value
			base.emitProperty('mode', SIGNATURE_MODE_SIGNING)
		}
		
		emitValue()
		createOnBehalfComponents()
	}, {
		immediate: true
	})
	
	watch(() => props.mode, (to, from) => {
		switch (to) {
			case SIGNATURE_MODE_PERSONS_SINGLE:
				personTransitionName.value = 'move-fade-right'
				currentSignatureType.value = null
				bankIDMode.value = 'init'
				break
			
			case SIGNATURE_MODE_ON_BEHALF:
				personTransitionName.value = from == SIGNATURE_MODE_PERSONS_MULTIPLE ? 'move-fade-right' : 'move-fade-left'
				currentSignatureType.value = null
				bankIDMode.value = 'init'
				break
			
			case SIGNATURE_MODE_PERSONS_MULTIPLE:
				personTransitionName.value = from == SIGNATURE_MODE_SIGNING ? 'move-fade-right' : 'move-fade-left'
				currentSignatureType.value = null
				bankIDMode.value = 'init'
				break
			
			case SIGNATURE_MODE_SIGNING:
				personTransitionName.value = 'move-fade-left'
				currentSignatureType.value = (dataPerson.value?.signature_type ?? null) as any
				break
		}
		
		multiSelectedPersons.value = []
		emitValue()
	}, {
		immediate: true
	})
	
	onMounted(async () => {
		EventBus.$on('beforeRouteChange', onLocationChange)
		
		let sessionRestored = await trySessionRestore()
		
		if (!sessionRestored) {
			if (requiredPersons.value.length === 1 && !props.power_of_attorney) {
				selectPersons([ requiredPersons.value[0] ])
				emitValue()
			} else {
				selectPersons(null)
				emitValue()
			}
		}
	})
	
	onBeforeUnmount(() => {
		EventBus.$off('beforeRouteChange', onLocationChange)
	})
	
	defineExpose({
		...base.expose,
		triggerErrorAnimationForSubComponent
	})
</script>

<style lang="scss">
	@import '@/assets/mixins.scss';
	
	.acquisit-signature {
		
		header.person-name {
			display: flex;
			
			padding: {
				top: 0px;
				bottom: 20px;
			}
			
			h2 {
				@include border-box;
				
				width: calc(100% - 24px);
				padding-left: 8px;
			}
			
			.person-icon {
				height: 24px;
				width: 24px;
				vertical-align: middle;
				margin-top: 8px;
			}
			
			&:not(.on-behalf) h2 {
				@include main-font(1.4rem, 600);
				@include ellipsis;
				
				line-height: 2.0;
			}
			
			&.on-behalf h2 {
				line-height: 1.3;
				@include main-font(1.0rem, 400);
				padding-top: 3px;
				
				.on-behalf-person-name,
				.on-behalf-name {
					display: block;
					@include main-font(1.3rem, 700);
					
					margin: {
						top: 6px;
						bottom: 6px;
					}
					
					color: color("text");
				}
			}
		}
		
		.person-chooser {
			$offset: 24px;
			padding-top: 16px;
			
			@include media(mobile) {
				ul {
					padding-top: 24px;
				}
			}
			
			.signature-persons-header {
				margin: 0;
				margin-bottom: 24px;
			}
			
			.init-on-behalf {
				padding-top: 28px - 24px;
				
				margin: {
					bottom: 56px;
				}
				
				.header {
					box-sizing: border-box;
					width: calc(100% + #{$offset} * 2);
					padding: 12px $offset;
					padding-top: 0;
					border-bottom: 1px solid color("border");
					
					margin: {
						left: -$offset;
						right: -$offset;
						bottom: 16px;
					}
					
					h2 {
						font-weight: 600;
						font-size: 1.067rem;
					}
				}
				
				.description {
					color: color("light-grey");
				}
				
				.description + .init-on-behalf-btn {
					margin-top: 8px;
				}
			}
			
			.signature-persons-header + .acquisit-info-box {
				margin-top: 8px;
			}
			
			.persons-header .acquisit-info-box {
				margin-bottom: 32px;
			}
		}
		
		.all-persons-signed-themselves-info {
			margin-bottom: 20px;
		}
		
		.on-behalf-container {
			
			.on-behalf-components-container {
				
				#on-behalf-explanation-label {
					margin-top: -8px;
				}
			}
		}
		
		.person-chooser-container {
			
			.person-chooser.multiple {
				margin-top: 16px;
			}
		}
		
		.signature-container {
			$maxSignatureTypeWidth: 360px;
			
			.other-options {
				margin-top: 24px;
				margin-bottom: 16px;
			}
			
			.signature-pad-placeholder {
				color: lighten(color("light-grey"), 15%);
				line-height: 1.0;
				
				display: flex;
				align-items: flex-start;
				
				//width: 100%;
				max-width: $maxSignatureTypeWidth;
				
				padding: {
					left: 24px;
					right: 24px;
				}
				
				.icon {
					width: 32px;
					height: 32px;
					
					svg {
						vertical-align: middle;
						margin-top: -12px;
					}
				}
				
				.meta {
					padding-left: 16px;
				}
				
				.person-name {
					display: block;
					line-height: 1.3;
					
					&:not(.on-behalf) {
						@include main-font(1.3rem, 600);
					}
					
					&.on-behalf {
						@include main-font(1.0rem);
						margin-bottom: 20px;
						
						.on-behalf-person-name,
						.on-behalf-name {
							display: block;
							@include main-font(1.3rem, 600);
							
							margin: {
								top: 6px;
								bottom: 6px;
							}
							
							color: color("text");
						}
					}
				}
				
				&.on-behalf {
					align-items: flex-end;
					
					.icon {
						
						svg {
							margin-top: 2px;
						}
					}
				}
				
				.signing-on-behalf-label {
					display: block;
					margin-bottom: 24px;
					
					.on-behalf-name {
					
					}
					
					.on-behalf-person-name {
					
					}
				}
			}
			
			.alternative-signature-types {
				@include border-box;
				
				width: 100%;
				max-width: $maxSignatureTypeWidth + 2 * 16px;
				
				padding: {
					left: 24px;
					right: 24px;
				}
				
				.person-name {
					display: block;
					text-align: center;
					font-size: 1.3rem;
					
					@include main-font(1.3rem, 600);
				}
				
				.person-name + .alternative-signature-type {
					margin-top: 16px;
				}
				
				.alternative-signature-type {
					@include box-shadow;
					
					display: flex;
					align-items: center;
					transition: all 0.2s;
					cursor: pointer;
					
					background: #fff;
					border-radius: 6px;
					// border: 1px solid color("border");
					
					padding: {
						left: 16px;
						right: 16px;
						top: 8px;
						bottom: 12px;
					}
					
					@media (hover: hover) {
						&:hover {
							@include box-elevated-shadow;
							
							.actions {
								transform: translateX(8px);
							}
						}
					}
					
					& + .alternative-signature-type {
						margin-top: 20px;
					}
					
					.meta {
						@include border-box;
						width: calc(100% - 32px);
						padding-right: 20px;
						
						&.with-image img {
							height: 38px;
						}
						
						&.with-icon {
							display: flex;
							align-items: flex-start;
							
							.icon {
								width: 30px;
								
								svg {
									width: 30px;
									height: 30px;
								}
							}
							
							.description {
								@include border-box;
								width: calc(100% - 32px);
								
								padding: {
									left: 12px;
									top: 3px;
								}
							}
							
							.title {
								font-size: 1.4rem;
								font-weight: 600;
								color: inherit;
								line-height: 1.3;
							}
							
							.subtitle {
								font-size: 1rem;
								color: color("dark");
								line-height: 1.3;
							}
						}
					}
					
					.actions {
						width: 32px;
						color: color("dark");
						transition: transform 0.2s;
						
						svg {
							width: 24px;
							height: 24px;
						}
					}
					
					&.type-bankid {
						color: #fff;
						background: color("bankID");
						
						.actions {
							color: #fff;
						}
						
						.meta.with-icon {
							
							.icon {
								color: #fff;
								
								svg, svg path {
									fill: currentColor;
								}
							}
							
							.subtitle {
								color: #fff;
							}
						}
						
						&:hover {
							background: lighten(color("bankID"), 10%);
						}
					}
					
					@include media(desktop) {
						&:hover {
							background: color("light-background");
						}
					}
				}
			}
			
			.signature-actions {
				display: flex;
				align-items: flex-start;
				justify-content: stretch;
				
				.reset {
					width: 100%;
					text-align: right;
				}
				
				.alternative-methods {
					width: 50%;
				}
				
				.alternative-methods + .reset {
					width: 50%;
				}
				
				.alternative-methods:last-child {
					width: 100%;
				}
				
				@media screen and (max-width: 400px) {
					flex-wrap: wrap;
					
					.alternative-methods {
						order: 2;
						width: 100%;
					}
					
					.reset {
						order: 1;
						margin-bottom: 16px;
					}
					
					.alternative-methods + .reset {
						width: 100%;
					}
				}
				
				.alternative-methods-btn {
					@include border-box;
					
					display: inline-block;
					background: #fff;
					border: 1px solid color("border");
					border-radius: 6px;
					text-align: center;
					cursor: pointer;
					transition: all 0.2s;
					color: color("light-grey-blue");
					width: 100%;
					max-width: $maxSignatureTypeWidth;
					
					padding: {
						left: 20px;
						right: 20px;
						top: 12px;
						bottom: 12px;
					}
					
					@media (hover: hover) {
						&:hover {
							background: color("light-background");
						}
					}
					
					.description {
						color: inherit;
					}
					
					.description + img {
						margin-top: 6px;
					}
					
					img {
						height: 20px;
					}
					
					.svg {
						display: inline-block;
						vertical-align: middle;
						height: 24px;
						width: 24px;
					}
					
					label {
						vertical-align: middle;
						margin-top: 3px;
						font-size: 1.4rem;
						cursor: pointer;
					}
					
					&.finger {
						display: block;
						margin: auto;
					}
					
					@include media(desktop) {
						&:hover {
							background: color("light-background");
						}
					}
					
					&.disabled {
						pointer-events: none;
						color: color("light-grey");
						
						img {
							filter: grayscale(100%);
							opacity: 0.5;
						}
					}
				}
			}
			
			.before-label,
			.after-label {
				margin-top: 24px;
			}
		}
		
		.error-summary {
			margin: {
				top: 16px;
				left: -24px;
				right: -24px;
			}
		}
		
		.no-persons-chosen {
			text-align: center;
			color: color("light-grey");
			
			padding: {
				top: 24px;
				bottom: 24px;
			}
			
			background: mix(color("light-grey-blue"), #fff, 8%);
			border-radius: 6px;
		}
	}
</style>
